import * as THREE from "three";
import Stats from "three/examples/jsm/libs/stats.module";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
// import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { STLLoader } from "three/examples/jsm/loaders/STLLoader";
import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer";
import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass";
// import { OutlinePass } from "three/examples/jsm/postprocessing/OutlinePass";
import { ShaderPass } from "three/examples/jsm/postprocessing/ShaderPass";
import { FXAAShader } from "three/examples/jsm/shaders/FXAAShader";
import TWEEN from "@tweenjs/tween.js";
// import GeometryManager from "./GeometryManager";
import MaterialManager from "./MaterialManager";
import ParticleManager from "./ParticleManager";

const textureLoader = new THREE.TextureLoader();
const stlLoader = new STLLoader();

// const wait = (s: number) =>
//   new Promise(resolve => {
//     setTimeout(resolve, s);
//   });

const isDev: boolean = process.env.NODE_ENV === "development";

export default class Skyline {
  container: HTMLDivElement;
  renderer: THREE.WebGLRenderer;
  camera: THREE.PerspectiveCamera;
  scene: THREE.Scene;
  orbit: OrbitControls | null = null;
  clock: THREE.Clock = new THREE.Clock();
  materialManager: MaterialManager | null = null;
  particleManager: ParticleManager | null = null;
  stats: Stats | null = null;
  skybox: THREE.Mesh | null = null;
  composer: EffectComposer;
  // outlinePass: OutlinePass;
  mesh: THREE.Mesh | null = null;

  constructor(container: HTMLDivElement) {
    this.container = container;

    this.renderer = new THREE.WebGLRenderer({ antialias: true });
    // this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.setSize(container.clientWidth, container.clientHeight);
    this.renderer.localClippingEnabled = true;
    container.appendChild(this.renderer.domElement);

    this.camera = new THREE.PerspectiveCamera(45, container.clientWidth / container.clientHeight, 0.01, 2000);
    this.scene = new THREE.Scene();

    this.composer = new EffectComposer(this.renderer);
    const renderPass = new RenderPass(this.scene, this.camera);
    this.composer.addPass(renderPass);

    // const rect = new THREE.Vector2(this.container.clientWidth, this.container.clientHeight);
    // this.outlinePass = new OutlinePass(rect, this.scene, this.camera);
    // this.outlinePass.renderToScreen = true;
    // this.outlinePass.edgeStrength = 2;
    // this.outlinePass.edgeGlow = 0.5;
    // this.outlinePass.edgeThickness = 1.5;
    // this.outlinePass.visibleEdgeColor.set(0x05d1e0);
    // this.outlinePass.hiddenEdgeColor.set(0x05d1e0);
    // this.composer.addPass(this.outlinePass);
  }

  _renderTimer: number = 0;
  runRenderLoop = () => {
    this._renderTimer = requestAnimationFrame(this.runRenderLoop);
    TWEEN.update();

    const dt = this.clock.getDelta();
    if (this.skybox) {
      this.skybox.rotation.y += dt * 0.05;
    }
    this.materialManager?.update();
    this.particleManager?.update(dt);
    this.orbit?.update();
    this.composer.render();
    this.stats?.update();
  };

  async init() {
    await this.initEnvironment();
    await this.setGraph(require("./models/iDerekLi-2022.stl"));
    await this.initMaterials();
    await this.initParticles();
    // await this.initEffects();
  }

  async initEnvironment() {
    this.camera.position.set(0, 20, 70);
    this.scene.fog = new THREE.Fog(0x040e22, 100, 400);
    this.scene.background = new THREE.Color(0x040e21);

    this.orbit = new OrbitControls(this.camera, this.renderer.domElement);
    this.orbit.minDistance = 50;
    this.orbit.maxDistance = 90;
    this.orbit.zoomSpeed = 1.24;
    this.orbit.enableZoom = true;
    this.orbit.enablePan = false;
    this.orbit.maxPolarAngle = 1.5;
    this.orbit.minPolarAngle = 0;
    // this.orbit.autoRotate = true;
    this.orbit.autoRotateSpeed = -0.8;
    this.orbit.enableDamping = true;

    if (isDev) {
      // 监控
      // @ts-ignore
      const stats = new Stats();
      stats.dom.style.bottom = 0;
      stats.dom.style.top = "auto";
      this.container.appendChild(stats.dom);
      this.stats = stats;

      // 坐标助手
      const axesHelper = new THREE.AxesHelper(50);
      this.scene.add(axesHelper);
    }

    // 地面网格
    const gridHelper = new THREE.GridHelper(1e3, 1e3 / 10, 0x6d96db, 0x6d96db);
    this.scene.add(gridHelper);

    // 天空盒
    const bgTexture = await textureLoader.loadAsync(require("./assets/skybox.png"));
    bgTexture.offset.y = 0.0368;
    const geometry = new THREE.SphereGeometry(1e3 / 2, 16, 4);
    const material = new THREE.MeshBasicMaterial({ map: bgTexture, side: THREE.BackSide, fog: false });
    const skybox = new THREE.Mesh(geometry, material);
    skybox.rotation.x = Math.PI;
    skybox.rotation.y = Math.PI;
    this.scene.add(skybox);
    this.skybox = skybox;

    this.runRenderLoop();
  }

  initParticles() {
    this.particleManager = new ParticleManager(this.scene, this.camera);
  }

  async setGraph(stlUrl: string) {
    const geometry = await stlLoader.loadAsync(stlUrl);

    const material = new THREE.MeshNormalMaterial();

    const wireframeMaterial = new THREE.MeshBasicMaterial({ color: 0xff0000, wireframe: true, transparent: true });

    const mesh = new THREE.Mesh(geometry, material);
    const wireframe = new THREE.Mesh(geometry, wireframeMaterial);
    wireframe.visible = false;

    mesh.add(wireframe);

    mesh.position.set(0, 4, 0);
    mesh.rotation.set(-Math.PI / 2, 0, 0);
    mesh.scale.multiplyScalar(0.5);
    this.scene.add(mesh);
    this.mesh = mesh;

    // 添加灯光
    const spotLight = new THREE.SpotLight(0xffffff, 1, 0, Math.PI / 5);
    spotLight.position.set(0, 520.8, 300.3);
    this.scene.add(spotLight);

    const spot2Light = new THREE.SpotLight(0xffffff, 0.3, 0, Math.PI / 5);
    spot2Light.position.set(0, 520.8, -300.3);
    this.scene.add(spot2Light);

    const spot3Light = new THREE.DirectionalLight(0xffffff, 1);
    spot3Light.position.set(500, 400, 300);
    this.scene.add(spot3Light);

    const spot4Light = new THREE.DirectionalLight(0xffffff, 0.8);
    spot4Light.position.set(-500, 400, -300);
    this.scene.add(spot4Light);
  }

  initMaterials() {
    this.materialManager = new MaterialManager(this.scene, {}, this);
    this.materialManager.startAnimation();
  }

  initEffects() {
    const effectFXAA = new ShaderPass(FXAAShader);
    effectFXAA.uniforms["resolution"].value.set(1 / this.container.clientWidth, 1 / this.container.clientHeight);
    this.composer.addPass(effectFXAA);
  }

  resize() {
    const container = this.container;
    this.camera.aspect = container.clientWidth / container.clientHeight;
    this.camera.updateProjectionMatrix();
    this.renderer.setSize(container.clientWidth, container.clientHeight);
  }

  dispose() {
    cancelAnimationFrame(this._renderTimer);
    this.renderer.dispose();
    this.orbit?.dispose();
    this.materialManager?.dispose();
  }
}
