import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CircularProgress, { circularProgressClasses } from "@mui/material/CircularProgress";
import Skyline from "./skyline";

const SkylineStyled = styled(Box)`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: radial-gradient(50% 100%, #180526 0, #040e21 100%);
`;

const LoadingContainer = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: radial-gradient(50% 100%, #180526 0, #040e21 100%);
`;

const Viewer = styled("div")`
  width: 100%;
  height: 100%;
`;

const Glow = styled("img")`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 200%;
  max-width: none;
  height: auto;
  transform: translate(-50%, -50%);
  pointer-events: none;
`;

const Preview = styled("img")`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  max-width: none;
  height: auto;
  transform: translate(-50%, -50%);
  pointer-events: none;
`;

const ProgressBar = styled(CircularProgress)(() => ({
  position: "absolute",
  bottom: "50%",
  left: "50%",
  color: "#e30a94",
  marginLeft: "-75px",
  marginTop: "-75px",
  [`& .${circularProgressClasses.circle}`]: {
    strokeLinecap: "round"
  }
}));

export default function SkylineApp() {
  const container = React.useRef<HTMLDivElement>(null);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    const skyline = new Skyline(container.current as HTMLDivElement);
    setLoading(true);
    skyline.init().then(() => {
      setLoading(false);
    });
    const onResize = () => {
      skyline.resize();
    };
    window.addEventListener("resize", onResize);
    return () => {
      skyline.dispose();
      window.removeEventListener("resize", onResize);
    };
  }, []);

  return (
    <SkylineStyled>
      {loading && (
        <LoadingContainer>
          <Preview src={require("./assets/grid_octocat.svg").default} />
          <ProgressBar style={{ width: "150px", height: "150px" }} />
        </LoadingContainer>
      )}
      <Viewer ref={container} />
      <Glow src={require("./assets/bg_glow.svg").default} />
      <Typography
        variant="h3"
        sx={{ position: "absolute", top: "50px", left: "50px", color: "#fff", pointerEvents: "none" }}
      >
        Skyline
      </Typography>
    </SkylineStyled>
  );
}
