import * as React from "react";
import Paper from "@mui/material/Paper";
import Skyline from "./Skyline";

export default function App() {
  return (
    <Paper sx={{ width: "100vw", height: "100vh", overflow: "auto" }}>
      <Skyline />
    </Paper>
  );
}
