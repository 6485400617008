import * as THREE from "three";
const axis1 = new THREE.Vector3();
const axis2 = new THREE.Vector3();
const axis3 = new THREE.Vector3();
const matrix4 = new THREE.Matrix4();

const textureLoader = new THREE.TextureLoader();

class Particle2 {
  lifetime: number = 5;
  time: number = 0;
  mesh: THREE.Sprite;

  constructor(mesh: THREE.Sprite) {
    this.mesh = mesh;
    this.lifetime = THREE.MathUtils.randFloat(3, 5);
    this.time = THREE.MathUtils.randFloat(0, this.lifetime);
  }

  update(dt: number) {
    if (this.time >= this.lifetime) {
      this.time = 0;
    } else {
      this.time += dt;
    }
    this.mesh.material.opacity = 1 - this.time / this.lifetime;
  }
}

class ParticleManager {
  scene: THREE.Scene;
  camera: THREE.PerspectiveCamera;
  dustParticles: Particle2[] = [];
  WORLD_SCALE: number = 1;
  particleUpdate: (() => void) | undefined;

  constructor(scene: THREE.Scene, camera: THREE.PerspectiveCamera) {
    this.scene = scene;
    this.camera = camera;

    this.initDust();
    this.initBullet();
  }

  initDust() {
    const color = [86, 56, 179];
    const sprite = textureLoader.load(require("./assets/particle.png"));

    const material = new THREE.SpriteMaterial({
      map: sprite,
      // blending: THREE.AdditiveBlending,
      // depthTest: false,
      depthWrite: false,
      transparent: true
    });
    material.color.setRGB(color[0] / 255, color[1] / 255, color[2] / 255);

    const particles = [];
    for (let i = 0; i < 50; i++) {
      const sprite = new THREE.Sprite(material.clone());
      const size = THREE.MathUtils.randFloat(1, 6);
      sprite.scale.set(size, size, size);
      const x = Math.random() * 200 - 100;
      const y = Math.random() * 200;
      const z = Math.random() * 200 - 100;
      sprite.position.set(x, y, z);
      const particle = new Particle2(sprite);
      particles.push(particle);
      this.scene.add(particle.mesh);
    }
    this.dustParticles = particles;
  }

  initBullet() {
    const height = 6;
    const width = (714 / 54) * height;
    const bulletGeometry = new THREE.PlaneGeometry(width, height);

    const map = textureLoader.load(require("./assets/light-bullet.png"));
    const bulletMaterial = new THREE.MeshBasicMaterial({
      map: map,
      side: THREE.FrontSide,
      depthTest: true,
      depthWrite: false,
      transparent: true,
      blending: THREE.AdditiveBlending
    });
    const bullet = new THREE.Mesh(bulletGeometry, bulletMaterial);

    const size = 400 * this.WORLD_SCALE;
    const y = 0 * this.WORLD_SCALE;
    const fullsize = 2 * this.WORLD_SCALE;

    const positions = [
      new THREE.Vector3(-size / 2, y, -20 * this.WORLD_SCALE),
      new THREE.Vector3(-size / 2, y, 30 * this.WORLD_SCALE),
      new THREE.Vector3(-size / 2, y, 0 * this.WORLD_SCALE),
      new THREE.Vector3(-20 * this.WORLD_SCALE, y, size / 2),
      new THREE.Vector3(30 * this.WORLD_SCALE, y, size / 2),
      new THREE.Vector3(0 * this.WORLD_SCALE, y, size / 2)
    ];

    const directions = [
      new THREE.Vector3(1, 0, 0),
      new THREE.Vector3(1, 0, 0),
      new THREE.Vector3(1, 0, 0),
      new THREE.Vector3(0, 0, -1),
      new THREE.Vector3(0, 0, -1),
      new THREE.Vector3(0, 0, -1)
    ];

    const scales = [0.38, 0.2, 0, 0.85, 0.6, 0.2];

    const particles: THREE.Mesh[] = new Array(positions.length).fill(null).map(() => bullet.clone());

    // init
    for (let i = 0; i < particles.length; i++) {
      const particle = particles[i];
      particle.position.copy(positions[i]).add(directions[i].clone().multiplyScalar(scales[i] * size));
      this.scene.add(particle);
    }

    // update
    this.particleUpdate = () => {
      for (let i = 0; i < particles.length; i++) {
        const particle = particles[i];
        particle.position.add(directions[i].clone().multiplyScalar(fullsize));
        particle.position.clone().sub(positions[i]).length() > size && particle.position.copy(positions[i]);

        axis1.copy(this.camera.position).sub(particle.position).normalize().multiplyScalar(1); // 当材质贴图是背面时 .multiplyScalar(-1); 当材质贴图是正面时可以是 .multiplyScalar(1); 也可以不传
        axis2.crossVectors(axis1, directions[i]);
        axis3.crossVectors(directions[i], axis2);
        matrix4.makeBasis(directions[i], axis2, axis3);
        particle.rotation.setFromRotationMatrix(matrix4);
      }
    };
  }

  update(dt: number) {
    this.particleUpdate && this.particleUpdate();

    this.dustParticles.forEach(particle => {
      particle.update(dt);
    });
  }
}

export default ParticleManager;
