import * as THREE from "three";
import TWEEN from "@tweenjs/tween.js";

export default class MaterialManager {
  scene: THREE.Scene;
  constants: any;
  skyline: any;
  materials: THREE.Material[] = [];
  animations: any[] = [];
  mesh: THREE.Mesh;

  constructor(scene: THREE.Scene, constants: any, skyline: any) {
    this.scene = scene;
    this.constants = constants;
    this.skyline = skyline;
    this.mesh = skyline.mesh;
    this.createMaterials();
    this.switchMaterial("wireframe");
    this.introAnimation();
  }

  createMaterials() {
    // const wireframeDarkgrey = new THREE.MeshStandardMaterial({
    //   wireframe: true,
    //   roughness: 0,
    //   emissive: new THREE.Color(190 / 255, 190 / 255, 190 / 255)
    // });
    // this.materials.push(wireframeDarkgrey);
    // 0
    const transparent = new THREE.MeshBasicMaterial({
      color: 0x000000,
      transparent: true,
      opacity: 0
    });
    this.materials.push(transparent);

    // 1
    const wireframeGradient = this.createGradientMaterial();
    wireframeGradient.uniforms.constant.value = -50;
    wireframeGradient.uniforms.offset.value = -50;
    wireframeGradient.uniforms.topColor.value.setRGB(1, 1, 1);
    wireframeGradient.uniforms.bottomColor.value.setRGB(228 / 255, 86 / 255, 245 / 255);
    this.materials.push(wireframeGradient);

    // 2
    const solid = new THREE.MeshPhongMaterial({
      color: 0x494b59,
      specular: 0x96633a,
      shininess: 30
    });
    this.materials.push(solid);

    // 3
    const solid2 = new THREE.MeshBasicMaterial({
      color: 0x494b59
    });
    this.materials.push(solid2);

    // 4
    const meshGradient = this.createGradientMaterial();
    meshGradient.wireframe = false;
    meshGradient.uniforms.constant.value = -50;
    meshGradient.uniforms.offset.value = -50;
    meshGradient.uniforms.topColor.value.setRGB(1, 1, 1);
    meshGradient.uniforms.bottomColor.value.setRGB(51 / 255, 47 / 255, 135 / 255);
    this.materials.push(meshGradient);
  }

  switchMaterial(type: string) {
    const mesh = this.mesh;
    const wireframe = this.mesh.children[0] as THREE.Mesh;
    if (type === "wireframe") {
      mesh.material = this.materials[4];
      wireframe.material = this.materials[1];
    } else if (type === "silver") {
      wireframe.material = this.materials[0];
      mesh.material = this.materials[2];
    }
  }

  startAnimation() {
    this.animations.forEach(animation => {
      animation.start();
    });
  }

  introAnimation() {
    // const mesh = this.mesh;
    const meshMaterial = this.mesh.material as THREE.ShaderMaterial;

    const wireframe = this.mesh.children[0] as THREE.Mesh;
    const wireframeMaterial = wireframe.material as THREE.ShaderMaterial;
    wireframe.visible = true;

    const animation1 = new TWEEN.Tween({ constant: -50 })
      .to({ constant: 50 }, 5e3)
      .easing(TWEEN.Easing.Sinusoidal.InOut)
      .onUpdate(coords => {
        wireframeMaterial.uniforms.constant.value = coords.constant;
      });
    const animation2 = new TWEEN.Tween({ offset: -50 })
      .to({ offset: 50 }, 5e3)
      .delay(600)
      .easing(TWEEN.Easing.Sinusoidal.InOut)
      .onUpdate(coords => {
        wireframeMaterial.uniforms.offset.value = coords.offset;
      });
    const animation3 = new TWEEN.Tween({ constant: -50 })
      .to({ constant: 50 }, 3e3)
      .delay(2400)
      .easing(TWEEN.Easing.Sinusoidal.InOut)
      .onUpdate(coords => {
        meshMaterial.uniforms.constant.value = coords.constant;
      });
    const animation4 = new TWEEN.Tween({ offset: -50 })
      .to({ offset: 50 }, 3e3)
      .delay(2500)
      .easing(TWEEN.Easing.Sinusoidal.InOut)
      .onUpdate(coords => {
        meshMaterial.uniforms.offset.value = coords.offset;
      });
    const animation5 = new TWEEN.Tween({ color: [51 / 255, 47 / 255, 135 / 255] })
      .to({ color: [1, 1, 1] }, 1000)
      .delay(5000)
      .easing(TWEEN.Easing.Quadratic.Out)
      .onStart(() => {
        wireframe.visible = false;
      })
      .onUpdate(coords => {
        meshMaterial.uniforms.bottomColor.value.setRGB(...coords.color);
      })
      .onComplete(() => {
        this.switchMaterial("silver");
      });

    // let direction = 1;
    // const loopGlow = new TWEEN.Tween({ edgeGlow: 0 })
    //   .to({ edgeGlow: 1 }, 3000)
    //   .easing(TWEEN.Easing.Linear.None)
    //   .delay(7000)
    //   .repeat(Infinity)
    //   .onStart(() => {
    //     this.skyline.outlinePass.selectedObjects = [this.mesh];
    //   })
    //   .onRepeat(() => {
    //     direction = direction > 0 ? -1 : 1;
    //   })
    //   .onUpdate(coords => {
    //     const edgeGlow = direction > 0 ? coords.edgeGlow : 1 - coords.edgeGlow;
    //     this.skyline.outlinePass.edgeGlow = edgeGlow;
    //   });

    this.animations.push(animation1);
    this.animations.push(animation2);
    this.animations.push(animation3);
    this.animations.push(animation4);
    this.animations.push(animation5);
    // this.animations.push(loopGlow);
  }

  update() {}

  createGradientMaterial() {
    const vertext = `
varying vec3 vWorldPosition;
void main() {
  vec4 worldPosition = modelMatrix * vec4( position, 1.0 );
  vWorldPosition = worldPosition.xyz;
  gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0 );
}
`;

    const fragment = `
uniform vec3 bottomColor;
uniform vec3 middleColor;
uniform vec3 topColor;
uniform float offset;
uniform float size;
uniform float constant;
varying vec3 vWorldPosition;
void main() {
  float h = vWorldPosition.x;
  float halfSize = size * 0.5;
  if (h > constant) {
    discard;
  }
  
  if (h > offset + halfSize) {
      gl_FragColor = vec4( topColor, 1.0 );
  } else if (h < offset - halfSize) {
      gl_FragColor = vec4( bottomColor, 1.0 );
  } else {
      gl_FragColor = vec4( mix( bottomColor, topColor, smoothstep(-1.0, 1.0, (h - offset) / halfSize) ), 1.0 );
  }
}
  `;
    const material = new THREE.ShaderMaterial({
      wireframe: true,
      uniforms: {
        constant: { value: 50.0 },
        offset: { value: -50.0 },
        size: { value: 10.0 },
        topColor: { value: new THREE.Color(1, 1, 1) },
        bottomColor: { value: new THREE.Color(0, 0, 0) }
      },
      vertexShader: vertext,
      fragmentShader: fragment
    });

    return material;
  }

  dispose() {}
}
